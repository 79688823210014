/* eslint-disable no-unused-vars */
import {
  Swiper,
  Pagination,
  Autoplay,
  Thumbs,
  Navigation,
  EffectFade,
} from 'swiper';
Swiper.use([Pagination,Autoplay,Thumbs,Navigation,EffectFade]);

export default {
  init() {


    //micrio sliders
    const thumbnails = new Swiper('.thumbnails', {
      spaceBetween: 10,
      slidesPerView: 12,
      freeMode: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      threshold:15,
    });

    const bigImage = new Swiper('.big-image', {
      spaceBetween: 10,
      threshold:30,
      allowTouchMove:false,
      thumbs: {
        swiper: thumbnails,
      },
    });

    //here you get your div(thumb) with data set
    let swiperData = document.querySelectorAll('.swiper-slide-thumbs');
    
    //here i create a for to get my click and show my slider with my data
    for(let i = 0, lgt = swiperData.length; i < lgt; i++) { 
        swiperData[i].onclick = function() { 
            var swiperGetData = swiperData[i].getAttribute('data-slide');
            console.log(swiperGetData)
            bigImage.slideTo(swiperGetData, 1000);   
        }
    }


    // JavaScript to be fired on all pages
    $('.scroll-down').on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: $(window).height(),
      }, 1000);
    });

    $('.back-top').on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: 0,
      }, 1000);
    });


     // mobile nav
     const $hamburger = $('.hamburger');
     const $nav = $('#mobile-nav-wrapper');
     $hamburger.on('click', function (e) {
       e.preventDefault();
       $hamburger.toggleClass('is-active');
       $nav.toggleClass('is-active');
       $('body').toggleClass('is-nav-open');
     });
 
     $('.close-mobile-nav').on('click', function (e) {
       e.preventDefault;
       $hamburger.removeClass('is-active');
       $nav.removeClass('is-active');
       $('body').removeClass('is-nav-open');
     });

    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
